<template>
  <v-card outlined>
    <v-toolbar flat>
      <v-toolbar-title>Inscriptions</v-toolbar-title>

      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        >
          <template v-slot:label>
            <span class="font-weight-bold">Rechercher</span>
          </template>
        </v-text-field>
      </template>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="registrations"
      :loading="registrationsAreLoading"
      :search="search"
      :custom-filter="customFilter"
      @click:row="goToRegistrationDetail"
    >
      <template v-slot:top v-if="$vuetify.breakpoint.xsOnly">
        <div class="px-4 d-flex">
          <v-spacer class="d-none d-sm-flex"></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            hide-details
          >
            <template v-slot:label>
              <span class="font-weight-bold">Rechercher</span>
            </template>
          </v-text-field>
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <span class="font-weight-medium"># {{ item.id }}</span>
      </template>

      <template v-slot:item.last_name="{ item }">
        <div class="d-flex align-center py-2">
          <v-icon
            class="mr-2"
            large
            :color="getGenderColor(item.gender)"
          >
            mdi-account-circle
          </v-icon>

          <div>
            <div class="font-weight-medium">
              {{ item.first_name }} {{ item.last_name.toUpperCase() }}
            </div>

            <div class="text-caption">
              {{ item.email }}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.birth_date="{ item }">
        {{ item.birth_date | formatDate }}
      </template>

      <template v-slot:item.gestac="{ item }">
        <v-icon
          v-if="item.gestac === true"
          color="success"
        >
          mdi-cloud-check
        </v-icon>

        <v-icon
          v-else
          color="error"
        >
          mdi-cloud-alert
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getGenderColor } from '@/mixins/methodsMixin';

export default {
  name: 'AdminParaRegistrations',

  props: {
    cursusId: String,
    instanceId: String,
  },

  mixins: [getGenderColor],

  data: () => ({
    items: [
      {
        text: 'Instances',
        disabled: false,
        to: '/',
      },
      {
        text: 'Cursus',
        disabled: false,
        to: '/pss',
      },
      {
        text: 'Inscriptions',
        disabled: true,
        to: '/registrations',
      },
    ],
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'ORDRE', value: 'order_number' },
      { text: 'ETUDIANT', value: 'last_name' },
      { text: 'DATE DE NAISSANCE', value: 'birth_date' },
      {
        text: 'GESTAC',
        value: 'gestac',
        sortable: false,
        filterable: false,
        align: 'right',
      },
    ],
    registrations: [],
    registrationsAreLoading: false,
    search: '',
  }),

  created() {
    this.getRegistrations();
  },

  methods: {
    async getRegistrations() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.registrationsAreLoading = true;
        const response = await this.$http.get(`admin/registration-get.php?cursus_id=${this.cursusId}&type=PARA`);
        this.registrations = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.registrationsAreLoading = false;
      }
    },

    customFilter(value, search, item) {
      const lowerCaseSearch = search.toLowerCase();
      if (value === item.last_name
      && (value.toLowerCase().indexOf(lowerCaseSearch) !== -1
      || item.first_name.toLowerCase().indexOf(lowerCaseSearch) !== -1
      || item.email.indexOf(lowerCaseSearch) !== -1)) {
        return true;
      }
      if (value === item.birth_date) {
        return this.$options.filters.formatDate(value).indexOf(lowerCaseSearch) !== -1;
      }
      return value.toString().indexOf(lowerCaseSearch) !== -1;
    },

    goToRegistrationDetail(row) {
      this.$router.push(`/admin/pss/${this.instanceId}/cursus/${this.cursusId}/registration/${row.id}`);
    },
  },
};
</script>
